<template>
  <dialog-view
      :open="isOpen" :loading="loadState.isLoading"
      :title="'Визит #'+(visitId!=null ? visitId : visit!=null ? visit.id : '')"
      big contentFullSize @cancel="close()"
  >
    <template v-slot:barActions v-if="isContentShow">
      <v-chip
        v-if="user.userProfile.discount>0"
        class="mr-2 font-weight-medium"
        color="green"
        text-color="white"
        small
      >
      Скидка {{user.userProfile.discount}} %
      </v-chip>
      <v-chip v-if="!visit.isSecond" small color="light" outlined class="mr-2 font-weight-medium">
        <v-icon color="primary" small class="pr-1">mdi-numeric-1-circle</v-icon>
        <span>Первый визит</span>
      </v-chip>
      <log entity-name="Visit" :entity-id="visitId"/>
    </template>
    <template v-slot:header v-if="isContentShow">
      <v-row>
        <v-col cols="auto" class="pb-0">
          <v-sheet color="transparent" max-width="440">
            <v-row no-gutters>
              <v-col cols="auto">
                <v-menu v-if="statuses.length>0" offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <div v-bind="attrs" v-on="on">
                      <label-view title="Статус">
                        <template v-slot:content>
                          <visit-status v-if="visitPaySummary!==null" :status="status" :isPaidFull="visitPaySummary.isPaidFull"/>
                        </template>
                      </label-view>
                    </div>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item v-for="statusValue in statusList" :key="statusValue.id" link class="text--primary" @click="statusUpdate(statusValue)">
                      <v-list-item-title>
                        <v-avatar :color="statusValue.color" size="14" class="v-overlay--absolute" style="margin-top:1px; position:absolute !important"/>
                        <div class="ml-6">{{statusValue.name}}</div>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col class="pt-6 pl-2">
                <v-btn v-if="visit.status==='pre'" @click="statusUpdate(statuses[1])" small depressed block color="orange" dark height="32">
                  <v-icon small class="mr-1">mdi-chevron-right</v-icon>Подтвердить
                </v-btn>
                <v-btn v-else-if="visit.status==='confirmed'" @click="statusUpdate(statuses[2])" small depressed block color="purple" dark height="32">
                  <v-icon small class="mr-1">mdi-chevron-right</v-icon>Пришел
                </v-btn>
                <v-btn v-else-if="visit.status==='ready'" @click="statusUpdate(statuses[3], true)" small depressed block color="blue" dark height="32">
                  <v-icon small class="mr-1">mdi-chevron-right</v-icon>Начать прием
                </v-btn>
                <v-btn v-else-if="visit.status==='active'" @click="statusUpdate(statuses[4], true)" small depressed block color="green" dark height="32">
                  <v-icon small class="mr-1">mdi-chevron-right</v-icon>Завершить
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="showInactiveStatuses">
                <v-row no-gutters>
                  <v-col cols="3" class="pr-2">
                    <v-btn @click="statusUpdate(statuses[5])" small depressed block color="red" dark>Не пришел</v-btn>
                  </v-col>
                  <v-col cols="3" class="pr-2">
                    <v-btn @click="statusUpdate(statuses[6])" small depressed block color="red" dark>Отказался</v-btn>
                  </v-col>
                  <v-col cols="3" class="pr-2">
                    <v-btn @click="statusUpdate(statuses[7])" small depressed block color="red" dark>Отказано</v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn @click="statusUpdate(statuses[8])" small depressed block color="grey" dark>Перенести</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col class="pb-0">
          <label-view title="Специалист">
            <template v-slot:content>
              <div class="v-overlay--absolute mt-1">
                <v-avatar class="user" size="36">
                  <img :src="visit.userSpec.user.imageUrl" v-if="visit.userSpec.user.imageUrl">
                </v-avatar>
              </div>
              <div class="ml-12 body-1 mt-n1">
                {{visit.userSpec.user.lastName != null ? visit.userSpec.user.lastName : ''}}
                {{visit.userSpec.user.firstName != null ? visit.userSpec.user.firstName : ''}}
                {{visit.userSpec.user.secondName != null ? visit.userSpec.user.secondName : ''}}
              </div>
              <div class="ml-12 body-2">
                {{visit.userSpec.spec.name}}
              </div>
            </template>
          </label-view>
        </v-col>
        <v-col class="pb-0">
          <label-view title="Кабинет / Место">
            <template v-slot:content>
              <div class="v-overlay--absolute mt-1">
                <tooltip-view v-if="!$tools.isEmpty(visit.comPlace.comRoom.comFloor.comBuilding.comBranch)" top :content="visit.comPlace.comRoom.comFloor.comBuilding.comBranch.name">
                  <template v-slot:action>
                    <v-avatar :color="visit.comPlace.comRoom.comFloor.comBuilding.comBranch.color" size="36" style="margin-top:-1px" text>
                      <span class="white--text">{{visit.comPlace.comRoom.sid}}</span>
                    </v-avatar>
                  </template>
                </tooltip-view>
              </div>
              <div class="ml-12 body-1 mt-n1">
                {{visit.comPlace.comRoom.name}}
              </div>
              <div class="ml-12 body-2">
                {{visit.comPlace.name}}
              </div>
            </template>
          </label-view>
        </v-col>
        <v-col cols="3" class="pb-0">
          <div>
            <label-view title="Дата / Время">
              <template v-slot:content>
                <v-row no-gutters>
                  <v-col class="body-1">
                    {{visitBeginDate}}
                    <v-row no-gutters class="mt-1">
                      <v-col>
                        <v-btn @click="getUserSpecPlanTimeRange(visit.begin,visit.end,visit.comPlace.id)" small depressed block color="grey lighten-2">{{visitBegin}} - {{visitEnd}}</v-btn>
                      </v-col>
                      <v-col class="px-2">
                        <btn-question
                            color="grey lighten-2" block small
                            title="Перенести визит?"
                            @click="isVisitsPage ? $emit('move', visit) : $router.push('/visit?visitMove='+visit.id)"
                        >
                          Перенести
                        </btn-question>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </label-view>
          </div>
          <dialog-view :open="visitBeginEndModal" title="Изменить время визита" @cancel="visitBeginEndModal=false;">
            <template v-slot:content>
              <time-range :loadState.sync="loadState" :begin.sync="visitBegin" :end.sync="visitEnd" :dayTimeBegin.sync="userSpecPlanTimeRange &&userSpecPlanTimeRange.begin" :dayTimeEnd.sync="userSpecPlanTimeRange &&userSpecPlanTimeRange.end"/>
            </template>
            <template v-slot:actions>
              <v-btn color="primary" text @click="visitBeginEnd()" :loading="visitBeginEndState.isLoading">ОК</v-btn>
            </template>
          </dialog-view>
        </v-col>
        <v-col cols="12" class="pa-0">
          <v-tabs v-model="menu" background-color="transparent" class="pa-0">
            <v-tab>Визит</v-tab>
            <v-tab>
              Другие визиты ({{otherVisitsCount}}) 
              <tooltip v-if="otherActiveVisitsCount>0" right>              
                <v-avatar class="ml-1" color="primary" size="20"><span class="text-caption white--text">+{{otherActiveVisitsCount}}</span></v-avatar>
                <template v-slot:content>
                  незавершенные визиты
                </template>
              </tooltip>
            </v-tab>
            <v-tab>В листе живой очереди ({{visitLivePlans.length}})</v-tab>
            <v-tab>В листе ожидания ({{visitFuturePlans.length}})</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </template>
    <template v-slot:content v-if="isContentShow">
      <v-tabs-items v-model="menu" optional>
        <v-tab-item>
          <div class="pa-4">
            <v-row>
              <v-col cols="7">
                <div class="caption">Клиент</div>
                <div class="title font-weight-regular mb-1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        @click="$tools.copyToClipboard(visit.user.lastName+' '+visit.user.firstName+' '+visit.user.secondName);$store.commit('alertSuccess', 'ФИО скопировано');"
                        style="cursor:pointer;"
                      >
                        <span class="mr-2">{{visit.user.lastName}}</span>
                        <span class="mr-2">{{visit.user.firstName}}</span>
                        <span>{{visit.user.secondName}}</span>
                      </span>
                    </template>
                    <span>Копировать</span>
                  </v-tooltip>
                  
                  <v-btn
                      icon depressed :href="'/panel/user/client/'+visit.user.id+'/'" target="visit_user_client">
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
      

                  <btn-question v-if="status.id!=='complete'" :loading="loadState.isLoading" class="ms-2" title="Изменить клиента?" color="grey lighten-3" small>
                    <template v-slot:default>Изменить клиента</template>
                    <template v-slot:content>
                      <div class="px-5 pt-5">
                        <user-autocomplete-single name="Выбрать клиента" :select.sync="newUser"/>
                      </div>
                    </template>
        
                      <template v-slot:footer="{ isOpen }" >
                        <v-btn text @click="isOpen(false)">Отмена</v-btn>
                        <v-spacer/>
                        <v-btn :disabled="!newUser" text @click="isOpen(false);changeUser();" color="primary">Ок</v-btn>
                      </template>
                  </btn-question>
                </div>
                

                <div class="font-weight-regular d-flex align-center">
                  <span class="mr-4"><v-icon class="mr-2 mt-n1" small>mdi-account-outline</v-icon>{{visit.user.userProfile.gender==='male' ? 'Мужчина' : 'Женщина'}}</span>
                  <span class="mr-4" v-if="birth != 'Invalid date'"><v-icon class="mr-2 mt-n1" small>mdi-calendar-star</v-icon>{{birth}} ({{ $tools.dateYears(birth) }})</span>
                  <span class="mr-2"><call-phone :phone="visit.user.phone"/></span> 
                  <v-btn
                    text
                    icon
                    x-small
                    @click="$tools.copyToClipboard(visit.user.phone);$store.commit('alertSuccess', 'Телефон скопирован');"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-checkbox v-model="visitFirst" :disabled="visitIsSecondState.isLoading" label="Первый визит" class="ml-4 mt-0 pt-0" dense hide-details />
                </div>

                <div class="mt-4">
                  <user-profile-tags-select :userProfileId="visit.user.userProfile.id" :defaultItems="visit.user.userProfile.userProfileTags" />
                </div>

                <v-alert v-if="userLoadState.isSuccess && userLoadState.valid" text dense type="success" class="mt-2 mb-0">
                  <v-row no-gutters align="center">
                    <v-col>Необходимая информация заполнена</v-col>
                    <v-col cols="auto"><v-btn text small light @click="(userLoadState.isModalOpen ? userLoadState.modalClose() : userLoadState.modalOpen());userShowErrors()">Изменить</v-btn></v-col>
                  </v-row>
                </v-alert>
                <v-alert v-else-if="userLoadState.isSuccess && !userLoadState.valid" text dense type="error" class="mt-2 mb-0">
                  <v-row no-gutters align="center">
                    <v-col>Не вся необходимая информация заполнена</v-col>
                    <v-col cols="auto"><v-btn text small light @click="(userLoadState.isModalOpen ? userLoadState.modalClose() : userLoadState.modalOpen());userShowErrors()">Изменить</v-btn></v-col>
                  </v-row>
                </v-alert>
                <v-alert v-else text dense color="info" class="mt-2 mb-0 text-center">
                  <v-progress-circular indeterminate color="primary" class="my-1" size="20" width="2"/>
                </v-alert>
                <v-sheet v-if="userLoadState.isModalOpen" outlined rounded class="my-2 pb-4">
                  <toolbar-view title="Информация">
                    <template v-slot:barActions>
                      <btn-question @click="basicInformationCheat()" color="amber darken-3" icon  size="default" class="mr-2" title="Заполнить основную информацию?">
                      <v-icon title="Закрыть">mdi-pencil-plus-outline</v-icon>
                      <template v-slot:content>
                          <div class="mx-5 my-5">
                            <v-alert
                              outlined
                              type="warning"
                              prominent
                              border="left"
                            >
                            Только для случаев, когда нет возможности взять паспортные данные!
                            </v-alert>
                          </div>
                      </template>
                    </btn-question>
                      <v-btn icon @click="userLoadState.modalClose()"><v-icon>mdi-close</v-icon></v-btn>
                    </template>
                  </toolbar-view>
                  <v-form ref="userForm">
                    <v-row v-if="user!==null" class="pt-2 mx-1">
                      <v-col cols="12" class="subtitle-2 text--secondary">Основная информация</v-col>
                      <v-col cols="4">
                        <simple-date-view :date.sync="user.userProfile.birth" title="Дата рождения" required/>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field v-model="user.userProfile.email" label="Email" outlined dense hide-details="auto"/>
                      </v-col>
                      <v-col cols="12" class="subtitle-2 text--secondary">
                        {{$tools.isDateUnder(user.userProfile.birth, 18) ? 'Свидетельство о рождении' : 'Паспорт'}}
                      </v-col>
                      <v-col cols="4">
                        <!-- <v-text-field v-mask="'####'" :maxlength="4" v-model="user.userProfile.pasSeries" label="Серия" :rules="formFieldRules.required" outlined dense hide-details="auto"/> -->
                        <v-text-field :maxlength="50" v-model="user.userProfile.pasSeries" label="Серия" :rules="formFieldRules.required" outlined dense hide-details="auto"/>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field :maxlength="50" v-model="user.userProfile.pasNumber" label="Номер" :rules="formFieldRules.required" outlined dense hide-details="auto"/>
                      </v-col>
                      <v-col cols="4">
                        <!-- <date-view :date.sync="user.userProfile.pasDate" title="Дата выдачи" required/> -->
                        <simple-date-view :date.sync="user.userProfile.pasDate" title="Дата выдачи" required/>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea v-model="user.userProfile.pasBy" label="Кем выдан" :rules="formFieldRules.required" rows="1" auto-grow outlined dense hide-details="auto"/>
                      </v-col>
                      <v-col cols="12" class="caption">Адрес регистрации</v-col>
                      <v-col cols="9">
                        <v-textarea v-model="user.userProfile.pasAddrStreet" label="Город, улица, дом" :rules="formFieldRules.required" persistent-hint auto-grow rows="1" outlined dense hide-details="auto"/>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field v-model="user.userProfile.pasAddrRoom" label="Квартира" outlined dense hide-details="auto"/>
                      </v-col>
                      <v-col cols="12"><v-divider/></v-col>
                      <!-- <v-col cols="12" class="subtitle-2 text--secondary">Первичный договор</v-col> -->
                      <v-col cols="12">
                        <v-text-field
                            v-model="user.userProfile.medCardNumber"
                            :label="'Номер мед. карты'+($tools.isEmpty(user.userProfile.medCardNumber) ? ' (по умолчанию '+user.id+')' : '')"
                            hint="Если не указан, по умолчанию будет использоваться id пользователя"
                            outlined dense hide-details="auto"/>
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                            v-model="user.userProfile.contractNumber"
                            :label="'Номер первичного договора'+($tools.isEmpty(user.userProfile.contractNumber) ? ' (по умолчанию '+user.id+')' : '')"
                            hint="Если не указан, по умолчанию будет использоваться id пользователя"
                            outlined dense hide-details="auto"/>
                      </v-col>
                      <v-col cols="3">
                        <date-view title="Дата" :date.sync="user.userProfile.contractDate" required/>
                        <!-- <simple-date-view :date.sync="user.userProfile.contractDate" title="Дата" required/> -->
                      </v-col>
                      <v-col cols="12" class="text-right">
                        <v-btn small depressed color="primary" @click="userSave()" :loading="userSaveState.isLoading">Сохранить</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-sheet>

                <v-sheet
                    rounded
                    outlined
                    :color="$tools.isEmpty(user.userProfile.medCardNumber) ? 'orange lighten-5' : 'green lighten-5'"
                    text dense class="mt-2 px-4 py-2"
                >
                  <v-row no-gutters align="center">
                    <v-col cols="auto" class="pr-3">
                      <v-icon :color="$tools.isEmpty(user.userProfile.medCardNumber) ? 'orange' : 'success'">
                        {{$tools.isEmpty(user.userProfile.medCardNumber) ? 'mdi-alert' : 'mdi-check-circle'}}
                      </v-icon>
                    </v-col>
                    <v-col :class="'body-1'+($tools.isEmpty(user.userProfile.medCardNumber) ? ' orange--text' : ' success--text')">
                      {{$tools.isEmpty(user.userProfile.medCardNumber) ? 'Мед.карта не создана' : 'Мед.карта создана'}}
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        v-if="$tools.isEmpty(user.userProfile.medCardNumber)"
                        @click="medCardNumberCreate()"
                        :loading="medCardNumberState.isLoading"
                        small light text
                      >
                        Создать
                      </v-btn>
                      <v-btn
                        v-else
                        @click="$tools.copyToClipboard(user.userProfile.medCardNumber);$store.commit('alertSuccess', 'Номер мед карты сопирован');"
                        :loading="medCardNumberState.isLoading"
                        small light text
                      >
                        #{{user.userProfile.medCardNumber}} <v-icon small class="ml-2">mdi-content-copy</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-sheet>

                <v-alert v-if="insuranceLoadState.isSuccess" text dense type="success" class="mt-2 mb-0">
                  <v-row no-gutters align="center">
                    <v-col>ОМС/ДМС</v-col>
                    <v-col cols="auto"><v-btn text small light @click="insuranceLoadState.isModalOpen ? insuranceLoadState.modalClose() : insuranceLoadState.modalOpen()">Изменить</v-btn></v-col>
                  </v-row>
                </v-alert>
                <v-alert v-else-if="insuranceLoadState.isError" text dense color="blue-grey" icon="mdi-alert" class="mt-2 mb-0">
                  <v-row no-gutters align="center">
                    <v-col>ОМС/ДМС не заполнено</v-col>
                    <v-col cols="auto"><v-btn text small light @click="insuranceLoadState.isModalOpen ? insuranceLoadState.modalClose() : insuranceLoadState.modalOpen()">Изменить</v-btn></v-col>
                  </v-row>
                </v-alert>
                <v-alert v-else text dense color="info" class="mt-2 mb-0 text-center">
                  <v-progress-circular indeterminate color="primary" class="my-1" size="20" width="2"/>
                </v-alert>
                <v-sheet v-if="insuranceLoadState.isModalOpen" outlined rounded class="my-2 pb-4">
                  <toolbar-view title="">
                    <template v-slot:barActions>
                      <v-btn icon @click="insuranceLoadState.modalClose()"><v-icon>mdi-close</v-icon></v-btn>
                    </template>
                  </toolbar-view>
                  <v-row class="pt-2 mx-1">
                    <v-col cols="12">
                  
                      <v-expansion-panels accordion v-model="panelOpen">
                        
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            ОМС
                            <div class="d-inline-block">
                              <v-chip v-if="$tools.isEmpty(user.userProfile.insurOms)" class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row class="pt-2">
                              <v-col cols="6">
                                <graphql-view
                                  query='
                                    query Query{
                                      Insur(oms:true){
                                        id
                                        name
                                      }
                                    }
                                  '
                                  :queryVars="{}"
                                >
                                  <template v-slot:content="{data}">
                                    <v-select
                                        label="Компания"
                                        v-model="user.userProfile.insurOms"
                                        :items="data.Insur"
                                        item-text="name"
                                        return-object
                                        clearable
                                        outlined dense hide-details
                                    />
                                  </template>
                                </graphql-view>
                              </v-col>
                              <v-col cols="6">
                                <date-view title="Дата окончания" :date.sync="user.userProfile.insurOmsEnd" />
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            ДМС
                            <div class="d-inline-block">
                              <v-chip v-if="$tools.isEmpty(user.userProfile.insurDms)" class="position-absolute ml-2 mt-n3" small>не заполнено</v-chip>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row class="pt-2">
                              <v-col cols="6">
                                <graphql-view
                                  query='
                                    query Query{
                                      Insur(dms:true){
                                        id
                                        name
                                      }
                                    }
                                  '
                                  :queryVars="{}"
                                >
                                  <template v-slot:content="{data}">
                                    <v-select
                                      label="Компания"
                                      v-model="user.userProfile.insurDms"
                                      :items="data.Insur"
                                      item-text="name"
                                      return-object
                                      clearable
                                      outlined dense hide-details
                                    />
                                  </template>
                                </graphql-view>
                              </v-col>
                              <v-col cols="6">
                                <date-view title="Дата окончания" :date.sync="user.userProfile.insurDmsEnd"/>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                      </v-expansion-panels>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <v-btn small depressed color="primary" @click="insuranceSave()" :loading="userSaveState.isLoading">Сохранить</v-btn>
                    </v-col>
                  </v-row>
                </v-sheet>


                <v-row>
                  <v-col cols="6">
                    <field-view
                        label="Комментарии к клиенту" :value="visit.user.userProfile.comment"
                        :url="'/api/visit/'+visit.id+'/'" field="userProfileComment" type="textarea" class="mt-4"
                    />
                  </v-col>
                  <v-col cols="6">
                    <field-view
                        label="Комментарии к визиту" :value="visit.comment"
                        :url="'/api/visit/'+visit.id+'/'" field="comment" type="textarea" class="mt-4"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="caption">Планы лечения (только активные)</div>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn @click="isMedPlanVisitServiceSelectOpen=true" small depressed>
                      Показать все
                    </v-btn>
                  </v-col>
                </v-row>
                <div v-if="medPlansState.isLoading" class="text-center">
                  <v-progress-circular indeterminate color="primary"/>
                </div>
                <div v-if="medPlansState.isSuccess">
                  <div v-if="medPlans.length>0">
                    <v-alert
                        v-for="medPlan in medPlans" :key="medPlan.id"
                        text dense
                        :type="medPlan.summary.wait>visitPaySummary.accountBalance ? 'error': 'success'"
                        class="mt-2 mb-0">
                      <v-row no-gutters align="center">
                        <v-col cols="12">{{medPlan.name}}</v-col>
                        <v-col cols="12" class="caption">
                          <span v-if="visitPaySummary.accountBalance>=medPlan.summary.wait">
                            Достаточно средств на личном счете
                          </span>
                          <span v-else>
                            Не достаточно средств на личном счете (Необходимо {{$tools.price(medPlan.summary.wait-visitPaySummary.accountBalance)}})
                          </span>
                        </v-col>
                        <v-col cols="12" class="caption">
                          Стоимость: {{$tools.price(medPlan.summary.total)}},
                          Осталось: {{$tools.price(medPlan.summary.wait)}},
                          Оплачено: {{$tools.price(medPlan.summary.success)}}
                        </v-col>
                      </v-row>
                    </v-alert>
                  </div>
                  <div v-else class="text-center">
                    <div>Не найдено активных</div>
                  </div>
                </div>
              </v-col>
              <v-col cols="5">
                <div class="caption">Представитель</div>
                <visit-rep-user :visitId="visit.id" :user="visit.repUser" @delete="visit.repUser=null" @created="(v)=>$nextTick(()=>visit.repUser=v)"/>
                <div class="caption mt-4 mb-2">Направляющий</div>
                <visit-lead-user :visitId="visit.id" :user="visit.leadUser" @delete="visit.leadUser=null" @created="(v)=>$nextTick(()=>visit.leadUser=v)"/>
              </v-col>
            </v-row>
          </div>
          <v-divider/>
          <div class="pa-4">
            <div class="caption">Услуги и оплата</div>
            <div v-if="visitPaySummary.visitServices.length>0">
              <v-simple-table class="visitTable" dense>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Название</th>
                    <th class="text-no-wrap text-right">Кол-во</th>
                    <th class="text-right">Цена</th>
                    <th class="text-right" v-if="!$inactiveStatuses.includes(status.id)">Скидка</th>
                    <th class="body-2 font-weight-medium text-right">Итого</th>
                    <th class="text-center">Статус оплаты</th>
                    <th v-if="visitPaySummary.visitServices.filter(v=>v.payStatus==='wait' || v.priceTotal===0).length>0"></th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr v-for="visitService in visitPaySummary.visitServices" :key="visitService.id">
                    <td>
                      <div>
                        <tooltip bottom>
                          {{ visitService.name }}
                          <template v-slot:content>
                            <div>ID в визите: {{ visitService.id }}</div>
                            <div>ID в прайс листе: {{ visitService.service.id }}</div>
                          </template>
                        </tooltip>
                      </div>
                      <v-chip v-if="visitService.type==='service'" class="mt-1 mr-2" small outlined>Услуга</v-chip>
                      <v-chip v-if="visitService.type==='serviceComposite'" class="mt-1 mr-2" small outlined>Составная услуга</v-chip>
                      <v-chip v-if="visitService.type==='product'" class="mt-1 mr-2" small outlined>Товар</v-chip>
                      <v-chip class="mt-1 mr-2" small outlined v-if="visitService.duration>0">{{visitService.duration}} мин.</v-chip>
                      <v-chip v-if="visitService.mode==='oms'" class="mt-1 mr-2" small outlined>
                        ОМС<v-divider class="mx-2" vertical/>{{visitService.insur.name}}
                      </v-chip>
                      <v-chip v-if="visitService.mode==='dms'" class="mt-1 mr-2" small outlined>
                        ДМС<v-divider class="mx-2" vertical/>{{visitService.insur.name}}
                      </v-chip>
                      <v-chip v-if="visitService.mode==='contract'" class="mt-1 mr-2" small outlined>
                        Контракт<v-divider class="mx-2" vertical/>{{visitService.insur.name}}
                      </v-chip>
                      <v-chip v-if="visitService.medplan!==null" class="mt-1 mr-2" small outlined>
                        План лечения<v-divider class="mx-2" vertical/>{{visitService.medplan.name}}
                      </v-chip>
                      <v-chip v-if="visitService.service.serviceLabItems.length>0" class="mt-1 mr-2" small outlined>
                        Лаборатория: <v-divider class="mx-2" vertical/>{{formatLab(visitService.service.serviceLabItems)}}
                      </v-chip>
            
                      <tooltip v-if="!$tools.isEmpty(visitService.comment)" bottom width="100%">
                        <v-chip  class="mt-1 mr-2" small outlined>
                          <v-icon small color="grey darken-5">mdi-comment-text-outline</v-icon>
                          <span class="caption ml-2">{{$tools.truncateText(visitService.comment,20)}}</span>                         
                        </v-chip>
                        <template v-slot:content>
                            <vue-markdown table-class="table">{{visitService.comment}}</vue-markdown>                
                        </template>
                      </tooltip>
                   
                      <v-expansion-panels v-if="visitService.service.serviceLabItems.length>0">
                        <v-expansion-panel style="background-color: transparent !important;">
                          <v-expansion-panel-header class="px-0">Анализы в лаборатории:</v-expansion-panel-header>
                          <v-expansion-panel-content>
    
                            <div v-for="(v1,k) in visitService.service.serviceLabItems" :key="k" cols="12" class="my-1">
                              <v-row no-gutters>
                                <v-col cols="auto" class="pr-2">{{v1.code}}</v-col>
                                <v-col>{{v1.name}}</v-col>
                                <v-col cols="auto">
                                  <service-lab-item-des :lab-id="v1.labId" :code="v1.code" :name="v1.name" />
                                </v-col>
                              </v-row>
                            </div>

                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>

                      <div v-if="visitService.type==='serviceComposite'" class="mt-2">
                        <div
                            v-for="visitServiceComposite in visitService.visitServiceComposites"
                            :key="visitServiceComposite.id"
                            class="mb-2"
                        >
                          <sheet-group>
                            <v-row>
                              <v-col>{{visitServiceComposite.name}}</v-col>
                              <v-col cols="auto">{{$tools.price(visitServiceComposite.priceTotal)}}</v-col>
                            </v-row>
                            <user-autocomplete-single
                                :name="visitServiceComposite.specComposite===null ? 'Любой специалист': 'Специалист: '+visitServiceComposite.specComposite.name"
                                :select="visitServiceComposite.userComposite"
                                @update:select="(v)=>visitServiceCompositeUserUpdate(visitServiceComposite, v)"
                                class="mt-3"
                                _type="spec"
                            />
                          </sheet-group>
                        </div>
                      </div>
                    </td>
                    <td class="text-right" style="vertical-align: top">
                      <v-text-field
                          v-if="visitService.payStatus==='wait'"
                          v-model="visitService.count"
                          @input="visitPaySummaryTimerIndex=Date.now()"
                          type="number" class="сountDiscount" background-color="white" hide-details outlined dense/>
                      <div v-else class="text-right">{{visitService.count}}</div>
                    </td>
                    <td class="text-no-wrap text-right pt-4" style="padding-top:18px !important;vertical-align: top">
                      {{ $tools.price(visitService.price) }}
                    </td>
                    <td class="text-no-wrap" style="vertical-align: top" v-if="!$inactiveStatuses.includes(status.id)">
                      <div v-if="visitService.payStatus==='wait'">
                        <percents-or-money :price="visitService.price" :discount.sync="visitService.discount" :count="visitService.count" @action="$tools.throttle(()=>{visitPaySummaryTimerIndex=Date.now();visitPaySummaryAction()},1000);"/>
                      </div>
                      <div v-else class="text-right">   
                        {{$tools.price(visitService.discount)}} / 
                        <template v-if="parseInt(visitService.price)!==0">
                          {{(visitService.discount/((visitService.price/100)*visitService.count)).toFixed()}} %
                        </template>
                        <template v-else>
                          0 %
                        </template>
                      </div>
                    </td>
                    <td class="text-no-wrap text-right font-weight-medium" style="padding-top:18px !important;vertical-align: top">
                      {{ $tools.price(visitService.priceTotal) }}
                    </td>
                    <td class="status pt-3" style="padding-top:16px !important;vertical-align: top">
                      <visit-service-status :status="visitService.payStatus"/>
                    </td>
                    <td v-if="visitPaySummary.visitServices.filter(v=>v.payStatus==='wait' || v.priceTotal===0).length>0" style="padding-top:10px !important;vertical-align: top">
                      <btn-icon-accept  v-if="visitService.payStatus==='wait' || visitService.priceTotal===0" icon="mdi-close" text="Удалить позицию?" @accept="visitServiceDelete(visitService)"/>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <dialog-view
                  title="Применить скидку?"
                  :open="confirmDiscount"
                  @cancel="confirmDiscount = false;"
              >
                <template v-slot:content>
                  <v-alert
                    outlined
                    type="warning"
                    prominent
                    border="left"
                  >
                    В одной или нескольких услугах из списка обнаружена скидка 100%<br> 
                    Вы уверены, что хотите применить скидку 100%?
                  </v-alert>
                </template>
                <template v-slot:actions>
                  <v-btn text color="primary" @click="visitPaySummaryAction(true);confirmDiscount = false">ОК</v-btn>
                </template>
              </dialog-view>
      
              <template v-if="!$inactiveStatuses.includes(status.id)">
                <v-divider class="my-3"/>
                <v-row class="justify-center align-center">
                  <v-col sm=auto v-if="totalDiscountType==='%'">
                    <div class="caption">Задать общую скидку:</div>
                    <v-btn-toggle dense>
                        <v-btn  v-for="d in discountArr" :key="d" icon :value="String(d)"  @click="totalDiscount = d">{{d}} %</v-btn>
                      </v-btn-toggle>
                  </v-col>
                  <v-col sm=2 class="pb-3 pt-8"> 
                    <div class="caption" v-if="totalDiscountType==='₽'">Задать общую скидку:</div>              
                    <percents-or-money :discount="totalDiscount" @action="totalDiscountCalc"/>
                  </v-col>
                </v-row>
              </template>
              <v-divider class="my-3"/>

            </div>

            <v-row class="mt-2" no-gutters>
              <v-col>
                <v-btn block depressed @click="visitServiceCreateState.modalOpen()">
                  Добавить из прайс листа
                </v-btn>
              </v-col>
            </v-row>

            <visit-service-add
              :open.sync="visitServiceCreateState.isModalOpen"
              :userspecId="visit.userSpec.user.id"
              :servicesId="servicesId"
              :insurOmsId="$tools.isEmpty(user.userProfile.insurOms) ? null : user.userProfile.insurOms.id"
              :insurDmsId="$tools.isEmpty(user.userProfile.insurDms) ? null : user.userProfile.insurDms.id"
              :visitBegin="visitBegin" 
              :visitEnd="visitEnd"
              @complete="visitServiceCreate"
            />

            <v-row class="body-2 mt-2 font-weight-medium text-center">
              <v-col v-if="visitPaySummaryState.isLoading" cols="12" class="text-center" style="height:68px">
                <v-progress-circular indeterminate color="primary"/>
              </v-col>
              <v-col v-if="visitPaySummaryState.isSuccess">
                <div>Личный счет</div>
                <div class="title">
                  {{$tools.price(visitPaySummary.accountBalance)}}
                </div>
              </v-col>
              <v-col v-if="visitPaySummaryState.isSuccess">
                <div>Начислено</div>
                <div class="title">
                  {{$tools.price(visitPaySummary.total)}}
                </div>
              </v-col>
              <v-col v-if="visitPaySummaryState.isSuccess">
                <div>
                  <span v-if="visitPaySummary.cancel>0">Отменено / </span>
                  <span>Оплачено</span>
                </div>
                <div class="title">
                  <span v-if="visitPaySummary.cancel>0">{{$tools.price(visitPaySummary.cancel)}} / </span>
                  <span>{{$tools.price(visitPaySummary.success)}}</span>
                </div>
              </v-col>
              <v-col v-if="visitPaySummaryState.isSuccess">
                <div class="text-no-wrap">Осталось оплатить</div>
                <div class="title">
                  <div class="d-inline-flex">
                    <div class="d-inline-block text-no-wrap text-left">
                      {{$tools.price(payWaitBeforeSum)}}
                      <div class="caption">прошедшие ({{payWaitBeforeCount}})</div>
                    </div>
                    <div class="d-inline-block px-2">/</div>
                    <div class="d-inline-block text-no-wrap text-left">
                      {{$tools.price(payWaitTodaySum)}}
                      <div class="caption">с начала дня ({{payWaitTodayCount}})</div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="justify-space-between">
              <v-col cols="auto">
                <div :id="'mediaTemplatePrint'+uniqId" class="position-relative"></div>

                <v-btn
                    block
                    depressed
                    :disabled="userLoadState.valid===false"
                    @click="mediaTemplatePrintLoad()"
                    :loading="mediaTemplatePrintLoadState.isLoading">
                  <v-icon small class="pr-2">mdi-printer</v-icon>Печать
                </v-btn>

                <v-menu v-model="mediaTemplatePrintLoadState.isModalOpen" :close-on-content-click="false"  max-height="700" min-width="480" :attach="'#mediaTemplatePrint'+uniqId" top>
                  <toolbar-view title="Выберите документы для печати">
                    <template v-slot:barActions>
                      <v-btn icon small class="mr-n2" @click="mediaTemplatePrintLoadState.isModalOpen=false"><v-icon small>mdi-close</v-icon></v-btn>
                    </template>
                  </toolbar-view>
                  <div class="mt-n2">
                    <v-row
                        @click="mediaTemplatePrints.indexOf(mediaTemplate.id)===-1 ? mediaTemplatePrints.push(mediaTemplate.id) : mediaTemplatePrints=mediaTemplatePrints.filter(v=>v!==mediaTemplate.id)"
                        v-for="mediaTemplate in $tools.filterByGlobalState(mediaTemplates, 'comBranchs')" :key="'mediaTemplate'+mediaTemplate.id"
                        class="px-3 py-1 full-width" no-gutters
                    >
                      <v-col cols="auto">
                        <v-checkbox :value="mediaTemplatePrints.indexOf(mediaTemplate.id)!==-1" dense hide-details class="pa-0" style="margin-top:-0.1rem"/>
                      </v-col>
                      <v-col class="clickable">
                        <tooltip-view v-if="!$tools.isEmpty(mediaTemplate.comBranch)" top :content="mediaTemplate.comBranch.name">
                          <template v-slot:action>
                            <v-avatar class="mr-2" :color="mediaTemplate.comBranch.color" size="10" style="margin-top:-1px"/>
                          </template>
                        </tooltip-view>
                        {{mediaTemplate.name}}
                        {{mediaTemplate.copyCount>1 ? '(копии: '+mediaTemplate.copyCount+')' : ''}}
                      </v-col>
                    </v-row>
                  </div>
                  <div class="ma-3">
                    <v-btn block depressed :disabled="mediaTemplatePrints.length===0" @click="mediaTemplatesPrint()">Печать</v-btn>
                  </div>
                </v-menu>
                <progress-page :loading="mediaTemplatePrintState.isLoading" icon="mdi-printer" dialog/>
                <dialog-view :open="mediaTemplatePrintState.isError" title="Ошибка создания документа" @cancel="mediaTemplatePrintState.stateInit()">
                  <template v-slot:content>
                    <div v-if="!$tools.isEmpty(mediaTemplatePrintState.errorMessage)" v-html="mediaTemplatePrintState.errorMessage.replace(/([^>])\n/g, '$1<br/>')"></div>
                  </template>
                </dialog-view>
              </v-col>
              <v-col cols="auto">
                <v-btn
                    depressed
                    :href="'/panel/user/client/'+visit.user.id+'/#media_medCard'" target="visit_user_client">
                  <v-icon class="pr-2" small>mdi-folder-file</v-icon> Мед. карта
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                    depressed
                    :href="'/panel/user/client/'+visit.user.id+'/#media_documents'" target="visit_user_client">
                  <v-icon class="pr-2" small>mdi-file-document</v-icon> Документы
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <lab-request
                    :userId="visit.user.id"
                    :visitServices="visitPaySummary.visitServices"
                    :comBranchId="visit.comPlace.comRoom.comFloor.comBuilding.comBranch.id"
                    :disabled="userLoadState.valid===false"
                />
              </v-col>
              <v-col cols="auto">
                <v-btn block depressed @click="visitIdPays=visitId">
                  <v-icon small class="pr-2">mdi-history</v-icon>Платежи
                </v-btn>
              </v-col>
              <v-col>
                <v-btn block depressed color="primary" :disabled="userLoadState.valid===false" @click="payCreateAccountInState.modalOpen()">
                  <v-icon small class="pr-2">mdi-plus</v-icon>Пополнить счет
                </v-btn>
              </v-col>
              <v-col>
                <v-btn  @click="visitsPayWaitsState.modalOpen()" block depressed color="primary" class="pa-0">
                  <v-icon small class="pr-2">mdi-cart</v-icon>Оплатить
                </v-btn>
              </v-col>
            </v-row>

          </div>
        </v-tab-item>
        <v-tab-item>
          
          <n-table-view
            ref="otherVisits"
            name="Список"
            :genRequestBuilder="visitsRequestBuilder"
            :headers="[
                    { text: 'ID', value: 'id', class:'text-no-wrap', sortable:false},
                    { text: 'Статус', value: 'status', class:'text-no-wrap', sortable:false},
                    { text: 'Специалист', value: 'userSpec', class:'text-no-wrap', sortable:false},
                    { text: 'Оплачено',  class:'text-no-wrap', sortable:false},
                    { text: 'Осталось',  class:'text-no-wrap', sortable:false},
                    { text: 'Дата визита', value: 'begin', class:'text-no-wrap', width: '100%', sortable:true},
                  ]" 
                  :sortBy="['begin']"       
            tableClass="tableStatused"      
            >
      
            <template v-slot:item="{ item }">
              <tr @click="otherVisitSelected=item.id">
                <td class="text-no-wrap">{{item.id}}</td>
                <td class="text-no-wrap">
                  <visit-status :status="$tools.findById(statuses, item.status)" :isPaidFull="item.paySummary.isPaidFull" :isSecond="item.isSecond" small/>
                </td> 
                <td class="text-no-wrap position-relative">
                  <div class="position-absolute mt-2">
                    <v-avatar class="user" size="32">
                      <img :src="item.userSpec.user.imageUrl" v-if="item.userSpec.user.imageUrl">
                    </v-avatar>
                  </div>
                  <div class="ml-10 mt-1">
                    {{item.userSpec.user.lastName != null ? item.userSpec.user.lastName + ' ' : ''}}
                    {{item.userSpec.user.firstName != null ? item.userSpec.user.firstName + ' ' : ''}}
                    {{item.userSpec.user.secondName != null ? item.userSpec.user.secondName + ' ' : ''}}
                  </div>
                  <div class="ml-10 caption mt-n1 mb-1">
                    {{item.userSpec.spec.name}}
                  </div>
                </td>
                <td class="text-no-wrap text-right">{{item.paySummary.success.toFixed(2)}} Р</td>
                <td class="text-no-wrap text-right">{{item.paySummary.wait.toFixed(2)}} Р</td>
                <td class="text-no-wrap full-width">{{$tools.date(item.begin)}} {{$tools.time(item.begin)}} - {{$tools.time(item.end)}}</td>
              </tr>
            </template>
          </n-table-view>

        </v-tab-item>
        <v-tab-item>
          <div v-if="loadState.isLoading || visitLivePlansState.isLoading" class="text-center pa-8">
            <v-progress-circular indeterminate color="primary"/>
          </div>
          <div v-if="loadState.isSuccess && visitLivePlansState.isSuccess" class="pa-4">
            <v-btn
                @click="visitPlanState.modalOpen({
                user:visit.user,
                isLiveList:true
              })"
                depressed
                class="mb-4"
            >
              Добавить
            </v-btn>
            <v-row v-if="visitLivePlans.length>0">
                <v-col
                    v-for="visitPlan in visitLivePlans" :key="'visitPlan'+visitPlan.id"
                    @click="visitPlanState.modalOpen(visitPlan)"
                    cols="4"
                    class="v-sheet"
                >
                  <v-sheet rounded color="grey lighten-4" class="px-4 py-2 fill-height clickable">
                    <div class="mb-1">К специалисту{{visitPlan.isLiveList ? '': ' '+$tools.date(visitPlan.begin)}}:</div>
                    <v-row v-if="visitPlan.userSpec!==null" no-gutters class="align-center">
                      <v-col cols="auto">
                        <v-avatar class="user" size="32">
                          <img :src="visitPlan.userSpec.user.imageUrl" v-if="visitPlan.userSpec.user.imageUrl" alt="">
                        </v-avatar>
                      </v-col>
                      <v-col class="pl-2">
                        <div class="font-weight-medium">
                          {{visitPlan.userSpec.user.lastName=== null ? '': visitPlan.userSpec.user.lastName}}
                          {{visitPlan.userSpec.user.firstName=== null ? '': visitPlan.userSpec.user.firstName}}
                          {{visitPlan.userSpec.user.secondName=== null ? '': visitPlan.userSpec.user.secondName}}
                        </div>
                        <div class="caption">
                          {{visitPlan.userSpec.spec.name}}
                          <v-icon small>mdi-chevron-left</v-icon>
                          {{visitPlan.userSpec.comDiv.comBranch.name}}
                          <div v-if="!$tools.isEmpty(visitPlan.userSpec.commentAdmin)" class="caption">
                            <v-icon class="mr-1" small>mdi-comment-text-outline</v-icon>
                            {{visitPlan.userSpec.commentAdmin}}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <div v-else class="font-weight-medium">
                      {{visitPlan.spec.name}}
                    </div>
                    <div v-if="!$tools.isEmpty(visitPlan.comment)" class="mt-1">
                      <div class="caption">Комментарий:</div>
                      <div class="caption">{{visitPlan.comment}}</div>
                    </div>
                  </v-sheet>
                </v-col>
              </v-row>
            <alert-view v-else icon="mdi-information-outline" text="Не найдено" />
          </div>
        </v-tab-item>
        <v-tab-item>
          <div v-if="loadState.isLoading || visitFuturePlansState.isLoading" class="text-center pa-8">
            <v-progress-circular indeterminate color="primary"/>
          </div>
          <div v-if="loadState.isSuccess && visitFuturePlansState.isSuccess" class="pa-4">
            <v-btn
              @click="visitPlanState.modalOpen({
                user:visit.user,
                isLiveList:false
              })"
              depressed
              class="mb-4"
            >
              Добавить
            </v-btn>
            <v-row v-if="visitFuturePlans.length>0">
              <v-col
                  v-for="visitPlan in visitFuturePlans" :key="'visitPlan'+visitPlan.id"
                  @click="visitPlanState.modalOpen(visitPlan)"
                  cols="4"
                  class="v-sheet"
              >
                <v-sheet rounded color="grey lighten-4" class="px-4 py-2 fill-height clickable">
                  <div class="mb-1">К специалисту{{visitPlan.isLiveList ? '': ' '+$tools.date(visitPlan.begin)}}:</div>
                  <v-row v-if="visitPlan.userSpec!==null" no-gutters class="align-center">
                    <v-col cols="auto">
                      <v-avatar size="32">
                        <img :src="visitPlan.userSpec.user.imageUrl" alt="">
                      </v-avatar>
                    </v-col>
                    <v-col class="pl-2">
                      <div class="font-weight-medium">
                        {{visitPlan.userSpec.user.lastName=== null ? '': visitPlan.userSpec.user.lastName}}
                        {{visitPlan.userSpec.user.firstName=== null ? '': visitPlan.userSpec.user.firstName}}
                        {{visitPlan.userSpec.user.secondName=== null ? '': visitPlan.userSpec.user.secondName}}
                      </div>
                      <div class="caption">
                        {{visitPlan.userSpec.spec.name}}
                        <v-icon small>mdi-chevron-left</v-icon>
                        {{visitPlan.userSpec.comDiv.comBranch.name}}
                        <div v-if="!$tools.isEmpty(visitPlan.userSpec.commentAdmin)" class="caption">
                          <v-icon class="mr-1" small>mdi-comment-text-outline</v-icon>
                          {{visitPlan.userSpec.commentAdmin}}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <div v-else class="font-weight-medium">
                    {{visitPlan.spec.name}}
                  </div>
                  <div v-if="!$tools.isEmpty(visitPlan.comment)" class="mt-1">
                    <div class="caption">Комментарий:</div>
                    <div class="caption">{{visitPlan.comment}}</div>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
            <alert-view v-else icon="mdi-information-outline" text="Не найдено" />
          </div>
        </v-tab-item>
      </v-tabs-items>
      <visit-pays :visitId="visitIdPays" @close="visitIdPays=null;load()" />
      <pay-create-account-in
          v-if="visitPaySummary!==null"
          :open="payCreateAccountInState.isModalOpen"
          :userId="visit.user.id"
          @complete="payCreateAccountInState.modalClose();load()"
      />
      <visit1
          :visitId.sync="otherVisitSelected"
          :users-specs="usersSpecs"
          :specs="specs"
      />
      <med-plan-visit-service-select :open.sync="isMedPlanVisitServiceSelectOpen" :userId="visit.user.id" @complete="visitServiceAdd"/>
      <visits-pay-waits
          :userId="visitsPayWaitsState.isModalOpen ? visit.user.id : null"
          :statuses="statuses"
          :sources="visitPaySummary.sources"
          :users-specs="usersSpecs"
          :specs="specs"
          @close="visitsPayWaitsState.modalClose();load()"
      />
      <visit-plan
          :state="visitPlanState"
          :users="usersSpecs"
          :specs="specs"
          @cancel="visitPlanState.modalClose()"
          @createdUpdatedDeleted="visitPlanState.modalClose();load();$emit('visitPlansUpdated')"
          @visitCreate="visitCreate"
      />
    </template>
  </dialog-view>

</template>

<script>
import State from "@/plugins/state";
import axios from "axios";
import moment from "moment";
import Log from "@/views/Log/Log";
import DialogView from "@/components/DialogView";
import VisitServiceAdd from "@/views/Visit/VisitServiceAdd.vue";
import Api from "@/Api";
import VisitStatus from "@/components/VisitStatus";
import VisitPays from "@/views/Visit/VisitPays";
import LabelView from "@/components/LabelView";
import VisitServiceStatus from "@/components/VisitServiceStatus";
import MedPlanVisitServiceSelect from "@/views/MedPlan/MedPlanVisitServiceSelect";
import VisitRepUser from "@/views/Visit/VisitRepUser";
import FieldView from "@/components/FieldView";
import VisitsPayWaits from "@/views/Visit/VisitsPayWaits";
import VisitPlan from "@/views/Visit/VisitPlan";
import AlertView from "@/components/AlertView";
import ProgressPage from "@/components/ProgressPage";
import CallPhone from "@/components/CallPhone";
import DateView from "@/components/DateView";
import SimpleDateView from "@/components/SimpleDateView";
import ToolbarView from "@/components/ToolbarView";
import {FormFieldRules} from "@/plugins/formFieldRules";
import VisitLeadUser from "@/views/Visit/VisitLeadUser";
import TimeRange from "@/components/TimeRange";
import GraphqlView from "@/components/GraphqlView";
import printJS from "print-js";
import payCreateAccountIn from "@/views/Pay/PayCreateAccountIn.vue";
import PayCreateAccountIn from "@/views/Pay/PayCreateAccountIn.vue";
import BtnQuestion from "@/components/btnQuestion.vue";
import UserProfileTagsSelect from "@/views/User/components/UserProfileTagsSelect.vue";
import PercentsOrMoney from "@/components/PercentsOrMoney";
import TooltipView from "@/components/TooltipView";
import LabRequest from "@/componentsV2/custom/LabRequest.vue";
import Tooltip from "@/componentsV2/base/Tooltip.vue";
import BtnIconAccept from "@/componentsV2/base/BtnIconAccept.vue";
import SheetGroup from "@/componentsV2/base/SheetGroup.vue";
import UserAutocompleteSingle from "@/componentsV2/custom/UserAutocompleteSingle.vue";
// import {Entity_UserSpecPlan, Entity_ComPlace} from "../../../EntityStoreCacheService";
import NTableView from "@/componentsV2/base/NTableView.vue";
import ServiceLabItemDes from "@/componentsV2/custom/ServiceLabItemDes.vue";

import {
  Entity_UserSpecPlan, 
  Entity_Visit, 
  Entity_User, 
  Entity_UserSpec,
  Entity_UserProfile, 
  Entity_Spec, 
  Entity_ComPlace, 
  Entity_ComRoom,
  Entity_MedPlan, 
} from "../../../EntityStoreCacheService";

export default {
  name: 'Visit',
  components: {
    ServiceLabItemDes,
    UserAutocompleteSingle,
    SheetGroup,
    BtnIconAccept,
    Tooltip,
    LabRequest,
    UserProfileTagsSelect,
    PayCreateAccountIn,
    VisitLeadUser,
    ToolbarView,
    DateView,
    SimpleDateView,
    CallPhone,
    ProgressPage,
    AlertView,
    VisitPlan,
    VisitsPayWaits,
    FieldView,
    VisitRepUser,
    MedPlanVisitServiceSelect,
    VisitServiceStatus,
    LabelView,
    VisitPays,
    VisitStatus,
    DialogView,
    Log,
    Visit1: () => import('@/views/Visit/Visit'),
    VisitServiceAdd,
    TimeRange,
    GraphqlView, 
    BtnQuestion,
    PercentsOrMoney,
    TooltipView,
    NTableView,
  },
  props: ['visitId', 'usersSpecs', 'specs', 'servicesId'],
  data: () => ({
    visit: null,
    loadState: new State(),
    medCardNumberState: new State(),
    statuses:Api.model.Visit.statuses,
    statusList: [],
    showInactiveStatuses:true,
    visitBeginEndModal: false,

    discountPercents:[],

    menu: 0,

    // visitsBegin: [],
    // visitsBeginState: new State(),
    // visitsBeginSelectIdStored: null,
    otherVisitsCount:0,
    otherActiveVisitsCount:0,
    otherVisitSelected: null,

    medPlansState: new State(),
    medPlans:[],

    repLastName:'',
    repFirstName:'',
    repSecondName:'',
    repGender:'',
    repBirth:'',
    repPhone:'',

    visitServiceCreateState: new State(),

    visitIdPays: null,

    visitPaySummaryState: new State(),
    visitPaySummaryTimer:null,
    visitPaySummaryTimerIndex:0,
    visitPaySummary:null,

    visitIsSecondState: new State(),

    payState: new State(),
    payCreateAccountInState: new State(),

    isMedPlanVisitServiceSelectOpen:false,

    payWaitBeforeSum: 0,
    payWaitBeforeCount: 0,
    payWaitTodaySum: 0,
    payWaitTodayCount: 0,

    visitsPayWaitsState: new State(),
    visitBeginEndState: new State(),

    uniqId:0,
    deleteState: new State(),

    mediaTemplatePrintLoadState: new State(),
    mediaTemplatePrints: [],
    mediaTemplates:[],
    mediaTemplatePrintState: new State(),

    visitLivePlansState: new State(),
    visitLivePlans:[],
    visitFuturePlansState: new State(),
    visitFuturePlans:[],
    visitPlanState: new State(),

    userLoadState: new State(),
    userSaveState: new State(),
    insuranceLoadState: new State(),
    user:null,
    formFieldRules: FormFieldRules,

    panelOpen: [],//oms,dms accordion
    isVisitsPage: true,

    totalDiscount:0,
    totalDiscountTimeout: null,
    totalDiscountType: '%',
    discountArr: [3,5,10,15,30,40,50],
    userSpecPlanTimeRange: null,
    confirmDiscount: false,

    newUser: null,
  }),
  watch: {
    visitId() {
      if (this.visitId!=null){
        this.totalDiscount = 0;
        this.userLoadState.valid=false;
        this.menu = 0;
        this.visit = null;
        this.visitServicesDiscount = 0;
        this.load();

        this.visitPaySummary = null;
        this.visitPaySummaryTimer = setInterval(()=>{
          if (this.visitPaySummaryTimerIndex!==0){
            if (Date.now()-this.visitPaySummaryTimerIndex>1000){
              this.visitPaySummaryTimerIndex = 0;
              this.visitPaySummaryAction();
              this.payWait();
            } else {
              this.visitPaySummaryState.stateLoading();
            }
          }
        }, 0, 100);
      } else {
        clearInterval(this.visitPaySummaryTimer);
      }
    },
    isMedPlanVisitServiceSelectOpen(){
      if(!this.isMedPlanVisitServiceSelectOpen)
        this.medPlansLoad();
    },
    'user.userProfile.insurOms'(v){
      if(v===null) this.user.userProfile.insurOmsEnd = null;
    },
    'user.userProfile.insurDms'(v){
      if(v===null) this.user.userProfile.insurDmsEnd = null;
    }
  },
  computed: {
    payCreateAccountIn() {
      return payCreateAccountIn
    },
    isOpen: {
      get: function() {
        return this.visitId!=null;
      },
      set: function() {}
    },
    birth: {
      get: function() {
        return moment(this.visit.user.userProfile.birth, 'YYYY-MM-DD').format('DD.MM.YYYY');
      },
      set: function(birth) {
        let d = moment(birth, 'DD.MM.YYYY')
        if (birth.length===10 && d.isValid())
          this.visit.user.userProfile.birth = d.format('YYYY-MM-DD');
      }
    },
    status: {
      get: function() {
        if (this.visit.status==null) return null;
        let s = null;
        this.statuses.forEach((value)=>{
          if (value.id===this.visit.status)
            s = value;
        });
        return s;
      },
      set: function(status) {
        this.statuses.forEach((value)=>{
          if (value.id===status.id)
            this.visit.status = value.id;
        });
      },
    },
    visitBeginDate:{
      get: function() {
        return moment(this.visit.begin, 'YYYY-MM-DD HH:mm:ss').format('dddd, DD MMMM YYYY');
      },
      set: function() {}
    },
    visitBegin: {
      get: function() {
        return moment(this.visit.begin, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
      },
      set: function(begin) {
        if (begin.length===5){
          let d = moment(moment(this.visit.begin, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD ')+begin+":00", 'YYYY-MM-DD HH:mm:ss')
          if (d.isValid())
            this.visit.begin = d.format('YYYY-MM-DD HH:mm:ss');
        }
      }
    },
    visitEnd: {
      get: function() {
        return moment(this.visit.end, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
      },
      set: function(end) {
        if (end.length===5){
          let d = moment(moment(this.visit.end, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD ')+end+":00", 'YYYY-MM-DD HH:mm:ss');
          if (d.isValid())
            this.visit.end = d.format('YYYY-MM-DD HH:mm:ss');
        }
      }
    },
    visitsBeginSelectId: {
      get() {
        return this.visitsBeginSelectIdStored;
      },
      set(v) {
        this.visitsBeginSelectIdStored = v;
        if (v==null)
          this.load();
      }
    },
    visitFirst:{
      get(){
        return !this.visit.isSecond;
      },
      set(v){
        this.visitIsSecondState.stateLoading();
        axios.put(Api.host+'/visit/'+this.visit.id+'/', {
          isSecond:!v,
        }, {withCredentials: true})
            .then(v =>{
              this.visit = v.data;
              this.visitIsSecondState.stateSuccess();
            })
            .catch(() =>{
              this.visitIsSecondState.stateError();
            });
      }
    },
    isContentShow: {
      get: function() {
        return this.visit!==null && this.visitPaySummary!==null && this.user!==null
      },
      set: function() {}
    },
    formatLab(){
      return (serviceLabItem) =>{
        if (serviceLabItem.length>0) {
          let arr = [];
          serviceLabItem.forEach(l=>{
            arr.push(l.labId);
          })
          return arr.join(', ');
        } else {
          return '-';
        }
      }
    }
  },
  mounted() {
    if (this.$router.currentRoute.path.includes("/user/client/")) {
      this.isVisitsPage = false;
    }
    this.uniqId = moment().unix()+'_'+this.visitId;
  },
  methods: {
    load(){
      this.loadState.stateLoading();
      Api.service.visit.visit(this.visitId, v=> {
        if (this.visit===null)
          this.optionSelected = this.$tools.isEmpty(v.comment) ? null : 0;
        this.visit = v;
        // this.userSpecPlanBegin = moment(v.begin).format('HH:mm')
        // this.userSpecPlanEnd = moment().hour(23).minutes(59).seconds(59);
        this.loadState.stateSuccess();

        this.visitLivePlansLoad();
        this.visitFuturePlansLoad();
        this.userLoad();
        this.visitPaySummaryAction();
        this.payWait();

        //other visits count
        new Entity_Visit()
        .selects(Entity_Visit.id)
        .selects(Entity_Visit.status)
        .filterAnd([Entity_Visit.entity, Entity_Visit.id].join('.')+' !=?', this.visit.id)
        .filterAnd([Entity_Visit.entity, Entity_User.entity, Entity_User.id].join('.')+' =?', this.visit.user.id)
        //.filterAnd([Entity_Visit.entity, Entity_Visit.status].join('.')+' not in (?)',$inactiveStatuses)
        .page(1).onPage(1000)
        .request((r)=>{
          this.otherVisitsCount = r.count
          this.otherActiveVisitsCount = r.items.filter(i=>this.$activeStatuses.includes(i.status)&&i.status!=='complete').length
        }, (e)=>{
          console.error(e.response.data.message);
        })

        this.medPlansLoad();

      }, error => {
        this.loadState.stateError();
        console.log(error)
      });
    },
    visitsRequestBuilder(page, onPage, sortFields){
        let rb = new Entity_Visit()
          .selects(Entity_Visit.id)
          .selects(Entity_Visit.begin)
          .selects(Entity_Visit.comment)
          .selects(Entity_Visit.created)
          .selects(Entity_Visit.end)
          .selects(Entity_Visit.isSecond)
          .selects(Entity_Visit.leadUserComment)
          .selects(Entity_Visit.mark)
          .selects(Entity_Visit.status)

        
          //user
          .selects([Entity_Visit.user, Entity_User.id].join('.'))
          .selects([Entity_Visit.user, Entity_User.firstName].join('.'))
          .selects([Entity_Visit.user, Entity_User.lastName].join('.'))
          .selects([Entity_Visit.user, Entity_User.secondName].join('.'))
          .selects([Entity_Visit.user, Entity_User.phone].join('.'))
          .selects([Entity_Visit.user, Entity_User.userProfile, Entity_UserProfile.medCardNumber].join('.'))
    

          //userSpec
          .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.id].join('.'))
          .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.firstName].join('.'))
          .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.lastName].join('.'))
          .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.secondName].join('.'))
          .selects([Entity_Visit.userSpec, Entity_UserSpec.spec, Entity_Spec.name].join('.'))
          .selects([Entity_Visit.userSpec, Entity_UserSpec.user, Entity_User.imageUrl].join('.'))

          //leadUser
          .selects([Entity_Visit.leadUser, Entity_User.id].join('.'))
          .selects([Entity_Visit.leadUser, Entity_User.firstName].join('.'))
          .selects([Entity_Visit.leadUser, Entity_User.lastName].join('.'))
          .selects([Entity_Visit.leadUser, Entity_User.secondName].join('.'))
          .selects([Entity_Visit.leadUser, Entity_User.phone].join('.'))
          .selects([Entity_Visit.leadUser, Entity_User.userProfile, Entity_UserProfile.gender].join('.'))

          //comPlace
          .selects([Entity_Visit.comPlace, Entity_ComPlace.comRoom, Entity_ComRoom.sid].join('.'))
        
          //virtual
          .virtual(Entity_Visit.virtual_paySummary);
          
   
          rb.filterAnd([Entity_Visit.entity, Entity_Visit.id].join('.')+' !=?', this.visit.id)
          rb.filterAnd([Entity_Visit.entity, Entity_User.entity, Entity_User.id].join('.')+' =?', this.visit.user.id)


          rb.page(page).onPage(onPage)

          

          sortFields.forEach(v=>{
            rb.order(v.field, v.isAsc)
          })

          return rb;
      },
    // loadTime(){
    //   Api.service.visit.visit(this.visitId, v=> {
    //     this.visit = v;
    //     this.userSpecPlanBegin = moment(v.begin).format('HH:mm')
    //     this.userSpecPlanEnd = moment().hour(23).minutes(59).seconds(59);
    //   });
    // },

    medPlansLoad(){
      this.medPlansState.stateLoading();
      let MedPlanRequest = new Entity_MedPlan()
      .selects(Entity_MedPlan.id)
      .selects(Entity_MedPlan.name)
      .selects(Entity_MedPlan.begin)
      .selects(Entity_MedPlan.end)
      .selects(Entity_MedPlan.plannedDate)
      .selects(Entity_MedPlan.comment)
      .selects(Entity_MedPlan.status)
      //user
      .selects([Entity_MedPlan.user, Entity_User.id].join('.'))
      .selects([Entity_MedPlan.user, Entity_User.firstName].join('.'))
      .selects([Entity_MedPlan.user, Entity_User.lastName].join('.'))
      .selects([Entity_MedPlan.user, Entity_User.secondName].join('.'))
      //userSpec
      .selects([Entity_MedPlan.userSpec, Entity_User.id].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.type].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.firstName].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.lastName].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.secondName].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.userProfile, Entity_UserProfile.birth].join('.'))
      .selects([Entity_MedPlan.userSpec, Entity_User.userProfile, Entity_UserProfile.gender].join('.'))

      //virtual
      // .virtual(Entity_MedPlan.virtual_isOverDate)
      .virtual(Entity_MedPlan.virtual_summary)
    
      .filterAnd([Entity_MedPlan.entity, Entity_MedPlan.status].join('.')+' in (?)',['active','achieved'])//активный и цель достигнута
      .filterAnd([Entity_MedPlan.entity, Entity_MedPlan.user, Entity_User.id].join('.')+'=?',this.visit.user.id)
      .page(1).onPage(1000);
      
      MedPlanRequest.request((data)=>{
        this.medPlans = data.items
        this.medPlansState.stateSuccess(); 
      }, (e)=>{
        console.error(e.response.data.message);
        this.medPlansState.stateError(e)
      })  

      // this.medPlans = [];
      // axios.get('/api/medPlan/medPlan/?userId='+this.visit.user.id+'&status=active').then(v => {
      //   this.medPlans = v.data.data;
      //   this.medPlansState.stateSuccess();
      // }).catch(e =>{
      //   this.medPlansState.stateError();
      //   Api.responseError(e)
      // });
    },

    visitServiceCompositeUserUpdate(visitService, user){
      axios.put('/api/visitService/', [
        {
          id: visitService.id,
          userComposite: {
            id: user.id
          },
        }
      ])
    },

    close(){
      this.$emit('update:visitId', null);
      this.$emit('cancel');
    },
    visitCreate(v){
      this.visitPlanState.modalClose();
      this.close();
      this.$emit('visitCreate', v);
    },

    userLeadCreated(leadUser){
      this.visit.leadUser = leadUser;
    },

    statusUpdate(status, changeTime=false){
      this.loadState.stateLoading();
      let d = {
        status:status.id,
      };
      if(changeTime){
        let a = moment(this.visit.begin, 'YYYY-MM-DD HH:mm:ss');
        let b = moment(this.visit.end, 'YYYY-MM-DD HH:mm:ss');
        if(status.id==='active') {
          d.begin = moment().format('YYYY-MM-DD HH:mm:ss');
          let c = b.diff(a, 'minutes');
          d.end = moment().add(c<1 ? 1 : c, 'minutes').format('YYYY-MM-DD HH:mm:ss');
        }
        if(status.id==='complete') {
          let c = moment().diff(a, 'minutes');
          if(c<1)
            d.end = moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss');
          else
            d.end = moment().format('YYYY-MM-DD HH:mm:ss');
        }
      }

      axios.put(Api.host+'/visit/'+this.visit.id+'/', d, {withCredentials: true})
          .then(v =>{
            this.visit = v.data;
            this.loadState.stateSuccess();
          })
          .catch(() =>{
            this.loadState.stateError();
          });
    },
    visitBeginEnd(){
      this.visitBeginEndState.stateLoading();
      axios.put(Api.host+'/visit/'+this.visit.id+'/', {
        begin:this.visit.begin,
        end:this.visit.end,
      }, {withCredentials: true})
          .then(v =>{
            this.visit = v.data;
            this.visitBeginEndModal = false;
            this.visitBeginEndState.stateSuccess();
          })
          .catch((e) =>{
            this.visitBeginEndState.stateError();
            this.$store.commit('alertError', e.response.data.message);
          });
    },

    visitLivePlansLoad(){
      this.visitLivePlansState.stateLoading();
      axios.get(Api.host+'/visitPlan/?liveList=1&date='+moment().format('YYYY-MM-DD')+'&page=1&onPage=10000')
        .then((r) =>{
          this.visitLivePlans = [];
          r.data.data.forEach((v)=>{
            if(v.user.id===this.visit.user.id)
              this.visitLivePlans.push(v);
          });
          this.visitLivePlansState.stateSuccess();
        })
        .catch(e =>{
          this.visitLivePlansState.stateError();
          Api.responseError(e)
        });
    },

    visitFuturePlansLoad(){
      this.visitFuturePlansState.stateLoading();
      axios.get(Api.host+'/visitPlan/?liveList=0&date='+moment(this.visit.begin,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')+'&page=1&onPage=10000')
        .then((r) =>{
          this.visitFuturePlans = [];
          r.data.data.forEach((v)=>{
            if(v.user.id===this.visit.user.id)
              this.visitFuturePlans.push(v);
          });
          this.visitFuturePlansState.stateSuccess();
        })
        .catch(e =>{
          this.visitFuturePlansState.stateError();
          Api.responseError(e)
        });
    },


    del() {
      this.deleteState.stateLoading();
      axios.delete(Api.host+'/visit/'+this.visit.id+'/')
          .then(()=> {
            this.deleteState.stateSuccess();
            this.close();
            this.$store.commit('alertSuccess', 'Визит удален');
          })
          .catch((error)=> {
            this.deleteState.stateError();
            console.log(error)
          });
    },

    visitServiceCreate(services){
      this.loadState.stateLoading();
      Api.service.visit.serviceAdd(this.visitId, services, (v)=> {
        this.visit.begin = v.visit.begin;
        this.visit.end = v.visit.end;
        this.visitPaySummary = v;
        this.payWait();
        this.loadState.stateSuccess();

        this.statusListUpdate();

        if (v.warningMessage)//если из за service.duration визит растягивается и накладывается на следующий
          this.$store.commit('alertError', v.warningMessage);
      },()=> {
        this.loadState.stateError();
      });
    },
    visitServiceAdd(visitService){
      this.loadState.stateLoading();
      Api.service.visit.visitServiceAdd(this.visitId, visitService, (v)=> {
        this.visit.begin = v.visit.begin;
        this.visit.end = v.visit.end;
        this.visitPaySummary = v;
        this.payWait();
        this.loadState.stateSuccess();

        this.statusListUpdate();

        if (v.warningMessage)//если из за service.duration визит растягивается и накладывается на следующий
          this.$store.commit('alertError', v.warningMessage);
      },()=> {
        this.loadState.stateError();
      });
    },
    visitServiceDelete(visitService){
      this.loadState.stateLoading();
      Api.service.visit.visitServiceDelete(this.visitId, visitService.id, (v)=> {
        this.visit.begin = v.visit.begin;
        this.visit.end = v.visit.end;
        this.visitPaySummary = v;
        this.payWait();
        this.statusListUpdate();
        this.loadState.stateSuccess();
      },()=> {
        this.loadState.stateError();
      });
    },
    visitPaySummaryAction(forced=false){
      this.visitPaySummaryState.stateLoading();
      let params = null;
      let alert = null;
      if (this.visitPaySummary!==null) {
        params = {
          visitServices:[]
        };
        this.visitPaySummary.visitServices.forEach((v) => {
          console.log(v);
          if (v.payStatus === 'wait'&&parseInt(v.discount)>=parseInt(v.price)&&!forced) {
            this.confirmDiscount = true;
            alert++;
          }
          if (v.payStatus === 'wait'&&!alert)
            params.visitServices.push({
              id: v.id,
              count: v.count,
              discount: v.discount,
            });
        });
      }
      if (!alert)
      Api.service.visit.visitPaySummary(this.visitId, params, (v)=> {
        this.visit.begin = v.visit.begin;
        this.visit.end = v.visit.end;
        this.visitPaySummary = v;

        alert = null;
        this.visitPaySummaryState.stateSuccess();
        
        this.statusListUpdate();

        if (v.warningMessage)//если из за service.duration визит растягивается и накладывается на следующий
          this.$store.commit('alertError', v.warningMessage);

      },()=> {
        this.visitPaySummaryState.stateError();
      });
    },

    payWait(){
      this.visitPaySummaryState.stateLoading();
      axios.get(Api.host+'/visit/paySummaryWait/byUser/'+this.visit.user.id+'/', {withCredentials: true})
          .then(v =>{
            this.payWaitBeforeSum = 0;
            this.payWaitBeforeCount = 0;

            this.payWaitTodaySum = 0;
            this.payWaitTodayCount = 0;

            let today = moment().startOf('day').unix();
            let now = moment().unix();
            v.data.forEach((v1)=>{
              let d = moment(v1.begin, 'YYYY-MM-DD HH:mm:ss').unix();
              if(d>=today && d<=now){
                this.payWaitTodayCount++;
                this.payWaitTodaySum +=v1.paySummary.wait;
              } else if (d<today){
                this.payWaitBeforeCount++;
                this.payWaitBeforeSum +=v1.paySummary.wait;
              }
              this.payWaitSum +=v1.paySummary.wait;
            });
            this.visitPaySummaryState.stateSuccess();
          });
    },

    visitPaySourceClick(source){
      if (typeof source.error==='undefined'){
        if (source.id==='card')
          this.payCardState.modalOpen({
            isPay:this.payState.modalData,
            request:this.visitPaySummary.wait
          });
        if (source.id==='cash')
          this.payCashState.modalOpen({
            isPay:this.payState.modalData,
            request:this.payState.modalData ? this.visitPaySummary.wait : 0,
            amount:this.payState.modalData ? this.visitPaySummary.wait : ''
          });

        if (this.payState.modalData){
          if (source.id==='bankAccount')
            this.payBankAccountState.modalOpen()
          if (source.id==='account')
            this.payAccountState.modalOpen()
        }
        this.payState.modalClose(this.payState.modalData);
      }
    },

    userLoad(){
      this.userLoadState.stateLoading();
      Api.graphql(`
        query Query($id: Int){
          User(id:$id){
            id
            userProfile{
              id
              birth
              email
              pasBy
              pasDate
              pasSeries
              pasNumber
              pasAddrRoom
              pasAddrStreet
              contractNumber
              medCardNumber
              contractDate
              discount
              insurOmsEnd
              insurDmsEnd
              insurOms {
                id
                name
              }
              insurDms {
                id
                name
              }
            }
          }
        }
      `, {id:this.visit.user.id}, (data)=>{


        this.user = data.User[0];

        if(this.user.userProfile.contractDate == null) {
          this.user.userProfile.contractDate = moment().format()
        }

        /*
        if(this.user.userProfile.medCardNumber == null) {
          this.user.userProfile.medCardNumber = this.user.id;
        }
         */
        
      
        this.userLoadState.valid =
            !this.$tools.isEmpty(this.user.userProfile.birth)&&
            !this.$tools.isEmpty(this.user.userProfile.pasBy) &&
            !this.$tools.isEmpty(this.user.userProfile.pasDate) &&
            !this.$tools.isEmpty(this.user.userProfile.pasSeries) &&
            !this.$tools.isEmpty(this.user.userProfile.pasNumber) &&
            !this.$tools.isEmpty(this.user.userProfile.pasAddrStreet) &&
            !this.$tools.isEmpty(this.user.userProfile.contractDate);
        this.userLoadState.stateSuccess();


        if (!this.$tools.isEmpty(this.user.userProfile.insurOms)||!this.$tools.isEmpty(this.user.userProfile.insurDms)) {
          this.insuranceLoadState.stateSuccess()
        }else{
          this.insuranceLoadState.stateError()
        }
      }, (e)=>{
        this.userLoadState.stateError(e)
      });
    },

    userShowErrors(){
      setTimeout(()=>{
        this.$refs.userForm.validate();
      }, 500);
    },

    userSave(){
      if (this.$refs.userForm.validate()) {
        this.userSaveState.stateLoading();
        Api.storage.createUpdate('UserProfile', this.user.userProfile, () => {
          this.userLoadState.modalClose();
          this.userSaveState.stateSuccess();
          this.userLoad();
        }, (e) => {
          this.userSaveState.stateError();
          this.$store.commit('alertError', e.response.data.message);
        })
      }
    },

    insuranceSave(){
      this.userSaveState.stateLoading();
      Api.storage.createUpdate('UserProfile', this.user.userProfile, () => {
        this.insuranceLoadState.modalClose();
        this.userSaveState.stateSuccess();
        this.userLoad();
      }, (e) => {
        this.userSaveState.stateError();
        this.$store.commit('alertError', e.response.data.message);
      })
   
    },


    //tools
    mediaTemplatePrintLoad(){
      this.mediaTemplatePrintLoadState.stateLoading();
      axios.get(Api.host+'/media/mediaTemplate/?isCanPrintVisit=1')
          .then(r=>{
            this.mediaTemplates = r.data;
            this.mediaTemplatePrints = [];
            this.mediaTemplatePrintLoadState.stateSuccess();
            this.mediaTemplatePrintLoadState.modalOpen();
          })
          .catch(()=>{
            this.mediaTemplatePrintLoadState.stateError();
          });
    },

    mediaTemplatesPrint(){
      this.mediaTemplatePrintLoadState.modalClose();
      this.mediaTemplatePrintState.stateLoading();

      axios.post(Api.host+'/media/mediaTemplate/mediaCreateForPreview/', {
        visitId:this.visit.id,
        mediaTemplateIds:this.mediaTemplatePrints
      })
        .then(r=>{
          let images = [];
          r.data.forEach(v=>{
            v.previews.forEach(v1=>{
              images.push(v1);
            });
          });
          printJS({ printable: images, type: 'image', header: ''});
          this.mediaTemplatePrintState.stateSuccess();
        }).catch(e=>{
          this.mediaTemplatePrintState.stateError(e.response.data.errors);
        });

      /*
      let formData = new FormData();
      formData.append('visitId', this.visit.id);
      axios.post(Api.host+'/media/mediaTemplate/'+id+'/mediaCreate/', formData)
          .then(r=>{
            axios.get(Api.host+'/media/media/'+r.data.id+'/preview/')
                .then(r1=>{




                  this.mediaTemplatePrintState.stateSuccess();
                })
                .catch(()=>{
                  this.mediaTemplatePrintState.stateError();
                });
          })
          .catch((e)=>{
            this.mediaTemplatePrintState.stateError(e.response.data.errors);
          });

       */
    },
    //old
    /*
    save() {
      this.updateState.stateLoading();
      axios.put('/api/visit/'+this.visit.id+'/', {
        begin: this.visit.begin,
        end: this.visit.end,
        comPlace: this.visit.comPlace.id,
        comment: this.visit.comment,
        commentSpec: this.visit.commentSpec,
        promoCode: this.visit.promoCode,
        clubCard: this.visit.clubCard,

        status: this.visit.status,
        userSpec: this.visit.userSpec.id,
        userFirstName: this.visit.user.firstName,
        userLastName: this.visit.user.lastName,
        userSecondName: this.visit.user.secondName,
        userPhone: this.visit.user.phone,
        userProfileGender: this.visit.user.userProfile.gender,
        userProfileBirth: this.visit.user.userProfile.birth,
        userProfilePromoSource: this.visit.user.userProfile.promoSource,
      })
          .then(()=> {
            this.updateState.stateSuccess();
            this.close();
            this.$store.commit('alertSuccess', 'Визит сохранен');
          })
          .catch((error)=> {
            this.updateState.stateError();
            console.log(error)
          });
    },
     */

    basicInformationCheat() {
      if(this.$tools.isEmpty(this.user.userProfile.birth)) {
        this.user.userProfile.birth = moment().format();
      }

      if(this.$tools.isEmpty(this.user.userProfile.pasBy)) {
        this.user.userProfile.pasBy = '-';
      }

      if(this.$tools.isEmpty(this.user.userProfile.pasDate)) {
        this.user.userProfile.pasDate = moment().format();
      }

      if(this.$tools.isEmpty(this.user.userProfile.pasSeries)) {
        this.user.userProfile.pasSeries = '-'; 
      }
      if(this.$tools.isEmpty(this.user.userProfile.pasNumber)) {
        this.user.userProfile.pasNumber = '000000';
      }
      if(this.$tools.isEmpty(this.user.userProfile.pasAddrStreet)) {
        this.user.userProfile.pasAddrStreet = '-';
      }
      if(this.$tools.isEmpty(this.user.userProfile.contractDate)) {
        this.user.userProfile.contractDate = moment().format();
      }

    }, 
    totalDiscountCalc(obj){
        let udpate = false;
        this.totalDiscountType = obj.type;
        clearTimeout(this.totalDiscountTimeout);
        this.totalDiscountTimeout = setTimeout(()=> {
            let newDiscount = obj.discount;
            let totalPrice = 0;
            let services = this.visitPaySummary.visitServices;
            //get total price and refresh discounts
            services.forEach((s,index)=>{
              totalPrice += Number(s.price);
              this.visitPaySummary.visitServices[index].discount = 0;
            });
              
            if (obj.type ==='%'){
              services.forEach((s,index)=>{
                let price = this.visitPaySummary.visitServices[index].price;
                let count = this.visitPaySummary.visitServices[index].count;
                this.visitPaySummary.visitServices[index].discount = (newDiscount*((price/100)*count)).toFixed(0)
              });
              udpate = true;
            } 
            else if (obj.type === '₽') {  
              let newPrice = totalPrice - newDiscount;
              let diff = parseInt(Number(newPrice)-Number(totalPrice));

              if (newPrice<totalPrice) {

                  let servicesCount = services.length;

                  let i = 0;
                  let sum = 0;
                  while(i<Math.abs(diff)) {
                    let n = Number(this.visitPaySummary.visitServices[i].discount) + 1

                    if (n<this.visitPaySummary.visitServices[i].price) {
                      this.visitPaySummary.visitServices[i].discount = n;
                      sum++;
                    }
                    i++;
        
                    if (sum === Math.abs(diff)) {
                        break;
                    }
                    else if(i === servicesCount) {
                      i = 0;
                    }
                  }
                
              }
              
              udpate = true;
            }  

            if (udpate) {
              this.visitPaySummaryTimerIndex=Date.now();

              this.visitPaySummaryAction();
            }
          
              
        }, 500);
    },


    medCardNumberCreate(){
      this.medCardNumberState.stateLoading();
      axios.post(Api.host+'/user/'+this.user.id+'/medCardNumber/')
          .then((r) =>{
            this.user.userProfile.medCardNumber = r.data.medCardNumber;
            this.medCardNumberState.stateSuccess();
          })
          .catch(e =>{
            this.medCardNumberState.stateError();
            Api.responseError(e)
          });
    },

    getUserSpecPlanTimeRange(begin,end,complaceId) {
      //Получает время начала и конца выбранного плана работы, чтобы ограничить компонент выбора времени в визите/блокировке.
      this.loadState.stateLoading();
      this.userSpecPlanTimeRange = null;
      let builder = new Entity_UserSpecPlan()
      .selects(Entity_UserSpecPlan.id)
      .selects(Entity_UserSpecPlan.begin)
      .selects(Entity_UserSpecPlan.end)

      builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.begin].join('.')+'<=?',moment(begin));
      builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.end].join('.')+'>=?',moment(end));
      builder.filterAnd([Entity_UserSpecPlan.entity, Entity_UserSpecPlan.comPlace, Entity_ComPlace.id].join('.')+' in (?)',[parseInt(complaceId)]);

      builder.page(1).onPage(3000);

      builder.request((data)=>{
        if (data.count>0) {
          this.userSpecPlanTimeRange = {
            begin: this.$tools.dateTimeFormat(data.items[0].begin, 'HH:mm'),
            end: this.$tools.dateTimeFormat(data.items[0].end, 'HH:mm')
          }
        }
        this.visitBeginEndModal=true;
        this.loadState.stateSuccess();
      }, (e)=>{
        console.error(e.response.data.message);
        this.loadState.stateError(e)
      })
    },
    changeUser() {
      let data = {
        'visitId':parseInt(this.visit.id),
        'oldUserId':parseInt(this.visit.user.id),
        'newUserId':parseInt(this.newUser.id)
      };
      this.loadState.stateLoading();
      Api.service.visit.changeUser(data, (data)=>{
        this.$store.commit('alertSuccess', data.message);
        this.newUser = null;
        this.load();
        this.loadState.stateSuccess();
      }, (e)=>{
        this.$store.commit('alertError', e.response.data.message);
        this.loadState.stateError();
      });
    },
    statusListUpdate() {
        //statuses if was paid or 100% discount
        this.statusList = this.statuses;
        if(this.visitPaySummary.visitServices.length>0&&this.visitPaySummary.visitServices.filter(vs=>vs.payStatus==='success').length>0) {
          let allowedStatuses = ['complete','ready','active']
          this.statusList = this.statusList.filter(status=> allowedStatuses.includes(status.id))
          this.showInactiveStatuses = false
        }  
        else if ((this.visit.status === 'pre' || this.visit.status === 'confirmed')&&this.visit.paySummary.success === 0) {
          this.showInactiveStatuses = true
        }
    }
  }
}
</script>
<template>
  <v-btn v-if="typeof big==='undefined'" @click="$store.commit('call', phone)" small depressed>
    <v-icon class="mr-2" small>mdi-phone-outline</v-icon>
    <span style="text-transform: initial !important">{{typeof title==='undefined' ? phone : title}}</span>
  </v-btn>
  <v-btn v-else @click="$store.commit('call', phone)" class="text" block depressed>
    <v-icon class="mr-2">mdi-phone-outline</v-icon>
    <span style="text-transform: initial !important">{{typeof title==='undefined' ? phone : title}}</span>
    <v-spacer />
  </v-btn>
</template>

<script>
export default {
  props: ['phone', 'big', 'title']
}
</script>


<template>
  <div :class="'visitStatuses'+(typeof small!=='undefined' ? ' small' : typeof xsmall!=='undefined' ? ' xsmall' : ' ')">
    <span v-if="typeof xsmall!=='undefined'">
      <v-sheet :color="status.color" class="pl-1 d-flex align-center" height="20">
        <v-icon v-if="isPaidFull" color="white" class="mr-1" size="14">mdi-checkbox-blank-circle</v-icon>
        <!-- <v-icon v-else color="white" class="mr-1" size="14">mdi-checkbox-blank-circle-outline</v-icon> -->
        <i v-else class="alert-ico mr-1"></i>
        <v-icon v-if="isSecond===false" color="white" class="mr-1" size="14">mdi-numeric-1-circle</v-icon>
        <v-icon v-if="isComment" color="white" class="mr-1" size="14">mdi-comment-text-outline</v-icon>
      </v-sheet>
    </span>
    <span v-else>
      <v-chip
          :color="status.color"
          :small="typeof small!=='undefined'"
          dark class="status white--text clickable" pill>
        <v-avatar left v-if="typeof filter==='undefined'">
          <v-icon v-if="isPaidFull" small>mdi-checkbox-blank-circle</v-icon>
          <i v-else class="alert-ico _big"></i>
          <!-- <v-icon v-else small>mdi-checkbox-blank-circle-outline</v-icon> -->
        </v-avatar>
        <span class="name">{{status.name}}</span>
      </v-chip>
      <v-icon v-if="isSecond===false" color="primary" class="ml-2" size="24">mdi-numeric-1-circle</v-icon>
    </span>
  </div>
</template>

<script>
export default {
  props: [
    'status',
    'isPaidFull',
    'isSecond',
    'isComment',
    'small',
    'xsmall',
    'filter'
  ],
}
</script>

<style lang="scss">
.visitStatuses{
  display: inline-block;
  .status{
    width: 162px !important;
    .v-chip__content{
      margin: initial !important;
      .v-avatar{
        margin-right: initial !important;
      }
    }
  }
}
.visitStatuses.small {
  .status {
    width: 140px !important;
  }
  .second{
    width:24px;
  }
}
.visitStatuses.xsmall {
  .status {
    width: 140px !important;
  }
  .second{
    width:24px;
  }
}
.white-wrapper {
  position: relative;
  &:before {
    content: "";
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    top: 3px;
    width: 10px;
    height: 10px;
  }
}
.alert-ico {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D.cls-2%7Bfill:%23f44336;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Ccircle class='cls-1' cx='12' cy='12' r='12'/%3E%3Cpath class='cls-2' d='M13,13H11V7h2m0,10H11V15h2M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")center center / contain no-repeat;
  width: 14px;
  height: 14px;
  display: inline-flex;
  flex-shrink: 0;
  &._big {
    width: 16px;
    height: 16px;
  }
}
</style>